export const joinATowerHuntTeam = {
  order: 2,
  name: "Join a Tower Hunt team",
  subcategory: "Getting started for new users",
  markdown: `# Join a Tower Hunt team
  In Tower Hunt, teams make it easy for groups of users to share datapoints and earnings.

  {% callout type="tip" %}
  **Tip:** To create a new team, {% inlineRouterLink articleId="getting-started-for-team-leaders" %}visit our guide{% /inlineRouterLink %} for step-by-step instructions.
  {% /callout %}

  ## 1. Join a team

  {% tablessContainer %}
  1. If you are already a Tower Hunt user and someone adds you to a team, no further action is needed!
  2. Tower Hunt informs you of your new team via email and {% inlineRouterLink %}news card{% /inlineRouterLink %}.
  {% /tablessContainer %}

  ## 2. Accept an invitation

  If you're not yet a Tower Hunt user, you'll need to accept an invitation and set up a new account:

  {% tablessContainer %}
  1. Check your inbox for an email invitation from Tower Hunt.
  2. Click **Get Started**.
  3. Enter your full name and a password, then click **Join**.
  {% /tablessContainer %}

  {% callout type="tip" marginStyles="mt-4" %}
  **Tip:** For help getting started with Tower Hunt, read our {% inlineRouterLink articleId="getting-started-for-new-tower-hunt-users" %}guide for new users{% /inlineRouterLink %}.
  {% /callout %}`,
};
